import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { useParams } from 'react-router-dom';

import {
    fetchAllEventsByGathering,
    fetchAllEventsByParticipants,
    sendAllEventsByEmail,
} from '../api/fetchAllEvents.js';
import { fetchGathering } from '../api/fetchGathering.js';
import { fetchAllParticipantsByGoogleEmail } from '../api/fetchParticipants.js';
import EventItem from '../components/EventItem.js';
import GroupSelect from '../components/GroupSelect.js';
import currentUserUtils from '../utils/currentUserUtils.js';
import { showToast } from '../utils/toastUtils.js';
import utils from '../utils/utils';

moment().locale('en');

function Events() {
    const { t, i18n } = useTranslation();
    let { id } = useParams();

    const [events, setEvents] = useState(null);
    const [gatheringName, setGatheringName] = useState('');
    const [gatheringLogo, setGatheringLogo] = useState('');
    const [initialMessage, setInitialMessage] = useState('');
    const [user, setUser] = useState(null);
    const [showEmailError, setShowEmailError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(
        t('Please enter a valid email')
    );

    // This page can be accessed as /group/[gathering_id], which provides a fixed url for bookmarks.
    let groupId = '';
    if (window.location.href.indexOf('/group/') > -1) {
        groupId = id;
    }

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    // This function tries to determine which logo and name to show at the top
    // of the Events page. In short, for logos: if the events list contains only
    // events for one Gathering, and the image_url for that Gathering is not
    // null, then we use that image as the logo. For all other scenarios, we use
    // the default logo. As for the name, we always use the Gathering name, if
    // we are able to successfully fetch it. In case of errors, we fall back on
    // showing the default Reevent logo and name.
    const setLogoAndName = async (eventsData) => {
        let showDefaultLogo = true;
        let showDefaultName = true;

        if (eventsData && eventsData.length > 0) {
            let uniqueGatheringIds = new Set();
            for (let i = 0; i < eventsData.length; ++i) {
                uniqueGatheringIds.add(eventsData[i].gathering_id);
            }
            if (uniqueGatheringIds.size === 1) {
                try {
                    // Only one unique gathering, grab it from the first.
                    const gatheringData = await fetchGathering(
                        eventsData[0].gathering_id
                    );

                    setGatheringName(gatheringData.name);
                    document.title = gatheringData.name;
                    showDefaultName = false;

                    if (
                        gatheringData.image_url != null &&
                        gatheringData.image_url !== ''
                    ) {
                        setGatheringLogo(gatheringData.image_url);
                        showDefaultLogo = false;
                    }
                } catch (exception) {
                    console.log(
                        'Error fetching gathering with id: ',
                        eventsData[0].gathering_id,
                        exception.toString()
                    );
                    showDefaultLogo = true;
                    showDefaultName = true;
                }
            }
        }

        if (showDefaultName) {
            setGatheringName('Reevent');
        }
        if (showDefaultLogo) {
            setGatheringLogo(utils.DEFAULT_EVENT_ICON);
        }
    };

    // Get all sessions.
    const getAllEventsWithActiveSession = async () => {
        let eventsData = [];
        let participantMap = currentUserUtils.getParticipantMap();

        setInitialMessage(t('Loading'));
        if (groupId !== '') {
            eventsData = await fetchAllEventsByGathering(groupId);
        } else if (Object.keys(participantMap).length !== 0) {
            eventsData = await fetchAllEventsByParticipants(
                currentUserUtils.getParticipantsAsArray()
            );
        } else {
            setInitialMessage(t('Invalid or missing group code'));
        }

        await setLogoAndName(eventsData);

        eventsData?.sort(function (a, b) {
            let dateA = moment(a.sessions[0].timestamp);
            let dateB = moment(b.sessions[0].timestamp);
            return dateA < dateB ? -1 : dateA === dateB ? 0 : 1;
        });
        setEvents(eventsData);
    };

    useEffect(() => {
        getAllEventsWithActiveSession();
    }, []);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error),
    });

    const fetchGoogleProfileData = async (user) => {
        try {
            const response = await axios.get(
                `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json',
                    },
                }
            );
            const participants = await fetchAllParticipantsByGoogleEmail(
                response.data.email
            );

            if (participants == null || participants.length === 0) {
                showToast('This email is not registered for any events', '', {
                    toastId: 'toastIdParticipantLookupByEmailFailed',
                    type: 'error',
                    autoClose: 4000,
                    isLoading: false,
                });

                return;
            }

            for (let i = 0; i < participants.length; ++i) {
                currentUserUtils.maybeSaveParticipantForOwner(
                    participants[i].owner_id,
                    participants[i].participant_id
                );
            }
            // This forces a re-render. Maybe there's an easier way?
            window.location.reload();
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (user) {
            fetchGoogleProfileData(user);
        }
    }, [user]);

    const checkEmail = (email) => {
        if (!email.includes('@') || !email.includes('.')) {
            setShowEmailError(true);
            return false;
        } else {
            setShowEmailError(false);
            return true;
        }
    };

    const sendEventsByEmail = async () => {
        const email = document.querySelector('.email-input').value;

        if (checkEmail(email)) {
            let response = await sendAllEventsByEmail(email);
            if (response === 'success') {
                setErrorMessage(t('Email sent!'));
                setShowEmailError(true);
                document.querySelector('.email-input').value = '';
            } else {
                setErrorMessage(response.message);
                setShowEmailError(true);
            }
        }
    };

    return (
        <div className="App">
            <div className="container main">
                <div className="columns main_inner">
                    <div className="column">
                        <div className="header">
                            <div className="brand">
                                <div className="logo">
                                    <div className="beta-tag">Beta</div>
                                    <img
                                        src={gatheringLogo}
                                        alt={gatheringName}
                                    />
                                </div>
                                <div className="name">{gatheringName}</div>
                            </div>
                            {/*<GoogleLoginButton />*/}
                            <div className="header-right">
                                {
                                    <>
                                        <div className="header_participant">
                                            <GroupSelect
                                                participantIds={currentUserUtils.getParticipantsAsArray()}
                                                gathering_id={groupId}
                                            ></GroupSelect>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="mainList">
                            {events ? (
                                <>
                                    <div id="title" className="title">
                                        {events.length > 0
                                            ? t('Next up')
                                            : t('No events found')}
                                    </div>
                                    {events.length === 0 && (
                                        <>
                                            <br />
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="email-box">
                                                        {t(
                                                            'Not seeing your events?'
                                                        )}
                                                        <br />
                                                        <br />
                                                        {/*
                                                        {t('Try signing in:')}
                                                        <br />
                                                        <br />{' '}
                                                        {
                                                            <button
                                                                onClick={() =>
                                                                    login()
                                                                }
                                                            >
                                                                {t(
                                                                    'Sign in with Google'
                                                                )}
                                                                <span
                                                                    role="img"
                                                                    aria-labelledby="rocket"
                                                                >
                                                                    🚀
                                                                </span>{' '}
                                                            </button>
                                                        }{' '}
                                                        <br />
                                                        {t(
                                                            'Enter your email to have a list of your upcoming events delivered to your inbox:'
                                                        )}
                                                        */}
                                                        <br />
                                                        <input
                                                            className="email-input"
                                                            type="email"
                                                            placeholder={t(
                                                                'Email'
                                                            )}
                                                            id="email-unauthenticated"
                                                        />
                                                        {showEmailError && (
                                                            <span className="email-error">
                                                                {errorMessage}
                                                            </span>
                                                        )}
                                                        <br />
                                                        <button
                                                            id="sendEventsByEmail"
                                                            onClick={
                                                                sendEventsByEmail
                                                            }
                                                        >
                                                            {t(
                                                                'Send me my events'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="column"></div>
                                            </div>
                                        </>
                                    )}
                                    <div className="events">
                                        {events.map((event, i) => (
                                            <EventItem
                                                event_id={event.event_id}
                                                session_id={
                                                    event.sessions[0].session_id
                                                }
                                                active={
                                                    event.sessions[0].active
                                                }
                                                key={event.event_id}
                                                name={event.name}
                                                date={utils.dateMaybeWithYear(
                                                    event.sessions[0].timestamp
                                                )}
                                                time={moment(
                                                    event.sessions[0].timestamp
                                                ).format('HH:mm')}
                                                venue={event.venue?.name}
                                                venue_link={
                                                    event.venue.location
                                                }
                                                short_description={
                                                    event.short_description
                                                }
                                                minParticipantCount={
                                                    event.sessions[0]
                                                        .min_participants
                                                }
                                                maxParticipantCount={
                                                    event.max_participants
                                                }
                                                type={event.type}
                                                t={t}
                                                i={i}
                                            />
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div>{initialMessage}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer"></div>
        </div>
    );
}

export default Events;

import moment from 'moment';
import 'moment/locale/is';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../api/AuthContext';
import { fetchAllFutureEventsByOwner } from '../api/fetchAllEvents.js';
import { fetchPastSessions } from '../api/fetchPastSessions.js';
import EventItemAdmin from '../components/EventItemAdmin.js';
import PastSessionItemAdmin from '../components/PastSessionItemAdmin.js';

moment().locale('is');

function Dashboard() {
    const auth = useAuth();

    const [events, setEvents] = useState(null);
    const [pastEvents, setPastEvents] = useState(null);

    // Get all events.
    const getAllEvents = async (e) => {
        const eventData = await fetchAllFutureEventsByOwner(
            auth.currentUser.user_id
        );
        setEvents(eventData);
    };

    useEffect(() => {
        getAllEvents();
    }, []);

    // Get all past events.
    const getPastEvents = async (e) => {
        const sessionData = await fetchPastSessions(auth.currentUser.user_id);
        setPastEvents(sessionData);
    };

    useEffect(() => {
        getPastEvents();
    }, []);

    const navigate = useNavigate();

    const handleAdd = () => {
        navigate('/admin/events/create');
    };

    return (
        <>
            <h1>Events</h1>
            <br />
            {events ? (
                <table className="table is-narrow is-striped is-smaller is-fullwidth">
                    <thead>
                        <tr>
                            <th>
                                <abbr title="Name of the event">
                                    Event template
                                </abbr>
                            </th>
                            <th>
                                <abbr title="Does the event repeat or is a single event">
                                    Info
                                </abbr>
                            </th>
                            <th>
                                <abbr title="Lost"></abbr>
                            </th>
                            <th>
                                <abbr title="Next session of this event">
                                    Next session
                                </abbr>
                            </th>
                            <th>
                                <abbr title="Lost"></abbr>
                            </th>
                            <th>
                                <abbr title="Lost"></abbr>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.map((event) => {
                            return (
                                <EventItemAdmin
                                    key={event.event_id}
                                    event={event}
                                    refreshEvents={getAllEvents}
                                />
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <div>Loading...</div>
            )}
            <button
                id="buttonAddEvent"
                className="button is-primary is-small"
                onClick={handleAdd}
            >
                + Create new
            </button>
            <br />
            <br />

            <h1>Past events</h1>
            <br />
            <div className="block ">
                {pastEvents ? (
                    <ul className="simple-list">
                        {pastEvents.map((session) => (
                            <PastSessionItemAdmin
                                key={session.session_id}
                                session={session}
                            />
                        ))}
                    </ul>
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        </>
    );
}

export default Dashboard;

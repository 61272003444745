import moment from 'moment';
import 'moment/locale/is';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

moment().locale('is');

// TODO(finnur): Investigate how to make these constants be part of shared/constants.cjs.
export const NO_STATUS = 0;
export const GAME_ON = 1;
export const MAXED_OUT = 2;
export const CANCELLED = 3;

// TODO(finnur): Remove time and rely on timestamp only when event/ page has stopped using this class.
export default function EventDetails({
    name,
    date,
    time,
    venue,
    attendanceCount,
    attendancePercentage,
    statusLabel,
}) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const handleBack = () => {
        navigate('/');
    };

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    return (
        <div className="eventDetails">
            <h1 id="eventName">{name}</h1>

            <div className="details">
                <div id="sessionDate" className="date">
                    {moment(date).format('D. MMM')}
                </div>
                <div id="sessionTime" className="time">
                    {moment(time, 'HH:mm:ss', true).format('HH:mm')}
                </div>
                {venue.location && (
                    <div className="venue">
                        <a
                            id="sessionVenue"
                            target="_blank"
                            href={venue.location}
                            rel="noopener noreferrer"
                        >
                            {venue.name}
                        </a>
                    </div>
                )}
                {!venue.location && <div className="venue">{venue.name}</div>}
            </div>

            <div className="backNav" tabIndex="0" onClick={handleBack}>
                {t('All events')}
            </div>

            <div className="participantCount">
                <span id="confirmCount">{attendanceCount}</span>
                {attendanceCount === 1
                    ? t('Confirmation (singular)')
                    : t('Confirmation (plural)')}

                <div className="progressBar">
                    <div
                        className="fillerStyles"
                        style={{
                            width: attendancePercentage + '%',
                        }}
                    ></div>
                </div>
                {statusLabel === GAME_ON && (
                    <div className="gameOn">{t('Game on!')}</div>
                )}
                {statusLabel === MAXED_OUT && (
                    <div className="gameOn">{t('Maxed out!')}</div>
                )}
                {statusLabel === CANCELLED && (
                    <div className="gameOn cancelled">{t('Cancelled')}</div>
                )}
            </div>
        </div>
    );
}
